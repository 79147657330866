<template>
  <div v-if="getActions.length > 1">
    <Actions
      :actions="getActions"
      @click-action="handleTableAction($event, pass)"
    ></Actions>
  </div>
</template>

<script>
import Actions from "@/v3components/shared/DataTable/Actions"
import { useStore } from "vuex"
import { computed, reactive, inject } from "vue"
import AptPassCancelation from "@/v3components/Forms/AptPassCancelation"
import CreateAppoinmentsPassForm from "@/v3components/Forms/CreateAppoinmentsPassForm"

export default {
  name: "AptPassAction",
  props: ["pass", "countType", "isPassBeyond"],
  emits: ["editAppPass"],
  components: { Actions },
  setup(props) {
    const store = useStore()

    const modal = inject("modal")

    const state = reactive({
      isLoading: false,
      allActions: [
        {
          label: "Acknowledge",
          icon: "ri-check-double-line",
          action: "acknowledge"
        },
        {
          label: "Confirm",
          icon: "ri-check-line",
          action: "confirm"
        },
        {
          label: "Edit",
          icon: "ri-checkbox-circle-line",
          action: "edit"
        },
        {
          label: "Cancel",
          icon: "ri-close-circle-line",
          action: "cancel"
        },
        {
          label: "Delete",
          icon: "ri-delete-bin-line",
          class: "important",
          action: "delete"
        }
      ]
    })

    const getActions = computed(() => {
      const action = []
      if (props.pass.is_for_acknowledge) {
        action.push(state.allActions[0])
      }
      if (props.pass.is_for_confirm) {
        action.push(state.allActions[1])
      }
      if (props.pass.can_be_edited) {
        action.push(state.allActions[2])
      }
      if (props.pass.can_be_canceled && !props.pass.cancel_reason) {
        action.push(state.allActions[3])
      }
      if (props.pass.can_be_deleted) {
        action.push(state.allActions[4])
      }
      return action
    })

    const confirmAppointmentPass = (passID) => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/confirmAppointmentPass", passID)
        .then(() => {
          state.isLoading = false
          incrementTodayCounter()
          decrementCurrentCounter()
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const acknowledgeAppointmentPass = (passID) => {
      state.isLoading = true
      store
        .dispatch("adultAptPass/acknowledgeAppointmentPass", passID)
        .then(() => {
          state.isLoading = false
        })
        .catch(() => {
          state.isLoading = false
        })
    }

    const editAppointmentPass = (pass) => {
      props.$emit("editAppPass", pass)
    }

    const decrementCurrentCounter = () => {
      if (
        props.countType === "appointments_future_awaiting" ||
        props.countType === "appointments_awaiting"
      ) {
        store.commit("dashboardTable/DECREMENT_STAT", props.countType)
      }
    }

    const incrementTodayCounter = () => {
      if (
        props.countType === "appointments_future_awaiting" ||
        props.countType === "appointments_awaiting"
      ) {
        store.commit("dashboardTable/INCREMENT_STAT", "todayAppointmentsCount")
      }
    }

    const handleTableAction = (event, item) => {
      if (event.action == "acknowledge") {
        acknowledgeAppointmentPass(item.id)
      }
      if (event.action == "confirm") {
        confirmAppointmentPass(item.id)
      }
      if (event.action == "edit") {
        modal.open(CreateAppoinmentsPassForm, {
          size: "md",
          title: "Edit appointment pass",
          props: {
            editablePass: item
          }
        })
      }
      if (event.action == "cancel") {
        modal.open(AptPassCancelation, {
          size: "md",
          title: "Cancel appointment pass",
          props: {
            pass: item,
            countType: props.countType,
            isBeyond: props.isPassBeyond
          }
        })
      }
      if (event.action == "delete") {
        modal.open(AptPassCancelation, {
          size: "md",
          title: "Cancel appointment pass",
          props: {
            pass: item,
            countType: props.countType,
            isBeyond: props.isPassBeyond
          }
        })
      }
    }
    return {
      confirmAppointmentPass,
      acknowledgeAppointmentPass,
      editAppointmentPass,
      decrementCurrentCounter,
      getActions,
      handleTableAction
    }
  }
}
</script>
